import * as React from 'react';
import Span from 'styleguide/components/Span/Span';
import Modal from 'styleguide/components/Modal/Modal';
import Grid from 'styleguide/components/Grid/Grid';
import css from './ReorderModal.scss';
import Loader from 'styleguide/components/Loader/Loader';
import startCase from 'lodash-es/startCase';

const ReorderModal = ({ action }) => (
  <Modal shown>
    <Grid.Container className={css.modalHeight}>
      <Grid>
        <Grid.Row>
          <Grid.Col sm={9}>
            <Span className={css.orderInProgress}>{`${startCase(action)} in progress...`}</Span>
          </Grid.Col>
          <Grid.Col sm={3}>
            <Loader size="xl" />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
  </Modal>
);

export default ReorderModal;
