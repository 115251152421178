import * as React from 'react';
import A from 'styleguide/components/Links/A';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link as RouterLink } from 'react-router-dom';
import { OrderBasic } from 'api/orders/types';
import VerticalDots from 'styleguide/icons/IconVerticalDots';

interface Props {
  order: OrderBasic;
}

const OrderMenu = ({ order }: Props) => (
  <Popover className="relative flex">
    {({ open }) => (
      <>
        <Popover.Button
          className={`
            ${
              open ? 'text-gray-300' : 'text-default'
            } no-underline' group inline-flex cursor-pointer items-center bg-shades-0 transition duration-300 ease-in-out hover:text-gray-300`}
        >
          <VerticalDots size="lg" className="ml-4" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 -translate-x-[80%] translate-y-[40%] transform rounded-lg shadow-xl">
            {() => (
              <>
                <div className="relative flex w-[190px] flex-col items-start rounded-t-lg bg-shades-0">
                  <Popover.Button
                    as={RouterLink}
                    to={`/account/orders/${order.number}/tracking`}
                    className="flex w-full px-4 py-4 pl-3
                                  text-sm text-default no-underline transition duration-300 ease-in-out hover:text-gray-300"
                  >
                    Track Order
                  </Popover.Button>
                </div>
                <div className="relative flex w-[190px] flex-col items-start rounded-b-lg bg-shades-0">
                  <Popover.Button
                    as={A}
                    href={`/account/invoice/${order.number}.pdf`}
                    color="black"
                    target="_blank"
                    underline="none"
                    className="flex w-full px-4 py-4 pl-3
                                  !text-sm text-default no-underline transition duration-300 ease-in-out hover:text-gray-300"
                  >
                    PDF Invoice
                  </Popover.Button>
                </div>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default OrderMenu;
